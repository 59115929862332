<template>
  <PCard>

    <PCardSection>
      <PDisplayText size="medium" element="h2">
        <b>
        Customer Database Size
        </b>
      </PDisplayText>

      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['fas','tshirt']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">

          <p><b> {{ this.$util.bigNumber(totalCustomersThisYrMonth) }} </b></p>

          <p>customers had shopped with you at least once</p>
          <p>by the start of {{ this.$util.monthStamp(report.reportDate,report.report.timezone) }} </p>

          <!--
          <p>
            vs {{ bigNumber(totalCustomersLastYrMonth) }}
          </p>
          -->
          </PDisplayText>

          <PDisplayText size="medium">
          <p :class="this.$util.redOrGreen(totalCustomersPercentageChange)"><b>
            {{ this.$util.sign(totalCustomersPercentageChange) }}
            {{ this.$util.unsign(this.$util.percentage(totalCustomersPercentageChange)) }} %
          </b></p>
          </PDisplayText>


          <PDisplayText size="small">
          <p>compared to last year</p>
          </PDisplayText>
        </PStackItem>

      </PStack>
    </PCardSection>


    <PCardSection>
      <ChartTotalCustomers
          :report="report"
          :timezone="report.report.timezone"
          :currency="currency"
          @totalCustomersUpdate="onTotalCustomersUpdate"
      />
    </PCardSection>

    <PCardSection title="Database Size" subdued>
      <PTextContainer>
        The total number of people on your database who have placed at least one order by the start of the month shown
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartTotalCustomers from '@/components/summary/charts/ChartTotalCustomers'


export default {
  name: "SummaryTotalCustomers",

  components: {
    ChartTotalCustomers
  },

  props: {
    report: { type: Object, required: true },
    currency: { type: String, required: true, },
  },

  data() {
    return {
      // Total customers
      totalCustomersThisYrMonth: 0,
      totalCustomersLastYrMonth: 0,
      totalCustomersPercentageChange: 0,
    }
  },

  methods: {
    onTotalCustomersUpdate(totalCustomersThisYrMonth,totalCustomersLastYrMonth,totalCustomersPercentageChange) {
      this.totalCustomersThisYrMonth = totalCustomersThisYrMonth
      this.totalCustomersLastYrMonth = totalCustomersLastYrMonth
      this.totalCustomersPercentageChange = totalCustomersPercentageChange
    },
  }


}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
