<template>
  <PCard>

    <PCardSection>

      <PDisplayText size="medium" element="h2">
        <b>
        Revenue
        {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
        </b>
      </PDisplayText>
      <PDisplayText size="small" element="h2">
        vs previous
        {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
      </PDisplayText>


      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['fas','chart-line']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
            <p><b>{{ this.$util.bigCurrency(currency,report.report.summary.revenue.thisYear.revenue) }} </b></p>
            <p>vs {{ this.$util.bigCurrency(currency,report.report.summary.revenue.lastYear.revenue) }} </p>
          </PDisplayText>

          <PDisplayText size="medium">
            <p :class="this.$util.redOrGreen(report.report.summary.revenue.growthPercentage)">
              <b>
                {{ this.$util.sign(report.report.summary.revenue.growthPercentage) }}
                {{ this.$util.unsign(this.$util.percentage(report.report.summary.revenue.growthPercentage)) }} %
              </b>
            </p>
          </PDisplayText>
        </PStackItem>
      </PStack>

    </PCardSection>


    <PCardSection>
      <ChartRevenueTrends
          :report="report"
          :currency="currency"
      />
    </PCardSection>

    <PCardSection title="Revenue Trends" subdued>
      <PTextContainer>
        <p>Are you getting more money that this time last year? If so great.  But why? And if not, same question.  As then maybe you can do better.</p>
        <p>We love these numbers at datatate as they tell you what you need to question.</p>
        <p>The revenue that datatate gives you is gross of returns/cancellations and VAT, but ignores delivery revenue.  It’s in your store’s main currency and is based on the date that a customer placed an order – ie it’s the demand you are generating.  This should help you see what is working, outside of other business factors.  But don’t forget to analyse them too!</p>

        <p>Us the graph to understand whether the revenue trend you are seeing this month is a new thing? Is your business consistently getting more revenue each month than the same month last year? What is the seasonality of your business? Is growth slowing or accelerating? If things stay the same what do you think the revenue will be this time next year?</p>
        <p>This will help you understand your business performance in context – is it getting better or worse? And do you know why? Always make sure you compare to the same time last year so you don’t mistake a seasonal trend for a change in performance.</p>
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartRevenueTrends from '@/components/summary/charts/ChartRevenueTrends'


export default {
  name: "SummaryRevenueTrends",

  components: {
      ChartRevenueTrends,
  },

  props: {
    report: { type: Object, required: true },
    currency: { type: String, required: true, },
  },


}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
