<template>
    <PPage>
      <PLayout>

        <PLayoutAnnotatedSection title="Datatate" description="Enter credentials">

          <PFormLayout>
            <PTextField type="text" label="Username" id="1" v-model="username" @input="validateInput"/>
            <PTextField type="password" label="Password" v-model="password" @input="validateInput" id="2"/>
            <PTextField type="text" label="Two Factor Authentication" v-model="otp" @input="validateInput" id="3"/>
            <PButton size="slim" primary :disabled="!enableLoginButton" @click="login">Login</PButton>
          </PFormLayout>

        </PLayoutAnnotatedSection>

      </PLayout>
    </PPage>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Login',

  data() {
    return {
      username: '',
      password: '',
      otp: '',

      enableLoginButton: false,
    }
  },

  computed: {
    ...mapGetters('authentication', [
      'defaultMerchantId',
    ]),
  },


  methods: {
    async login() {
      if (!this.enableLoginButton) return

      try {
        await this.$store.dispatch('authentication/login', { username:this.username, password:this.password, otp:this.otp })
        this.$router.replace({ name: 'Summary', params: { merchantId: this.defaultMerchantId } })
      }
      catch(error) {
        this.enableLoginButton = false
        this.$pToast.open({
          message: 'Invalid credentials',
          duration: 2000,
          error: true,
          position: 'bottom',
          onDismiss: () => {
            this.validateInput()
          }
        })
      }
    },

    validateInput() {
      this.enableLoginButton = (/^\S{2,}$/.test(this.username)) &&
          (/^\S{5,}$/.test(this.password)) &&
          (/^\d+$/.test(this.otp))
    },

  }, // methods

}
</script>

<style>
</style>
