<template>
  <GChart
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
  />
</template>

<script>
  import {DateTime} from 'luxon'
  import { GChart } from 'vue-google-charts'

  // Use K, M, etc....
  // https://stackoverflow.com/questions/15241212/google-chart-k-instead-of-thousands

  // ICU Decimal Pattern Set
  // http://cldr.unicode.org/translation/numbers-currency/number-patterns

  export default {
    name: "ChartRevenueTrends",

    components: {
      GChart,
    },

    props: {
      report: { type: Object, required: true },
      currency: { type: String, required: true, },
    },

    watch: {
      report: function(newReport) {
        this.updateData()
      }
    },

    data() {
      return {
        google: null,
        chartData: null,
      }
    },

    computed: {
      chartOptions() {
        return {
          height: "400",
          title: 'Revenue',
          //backgroundColor: '#EEEEEE',
          titleTextStyle: {
            fontSize: 16,
          },
          curveType: 'none',
          legend: {
            position: 'right',
          },
          chartArea: {
            right: '15%'
          },
          vAxis: {
            /*
            title: this.currency,
            titleTextStyle: {
              fontSize: 15,
              bold:true,
            },
            format: 'short',
            */
            //format: this.currency + '#,###',
            format: this.currency + '#,###',
            minValue: 1, // Chart bug workaround that forces zero to bottom of axis
            title: 'Revenue',
          },
          hAxis: {
            title: 'Month',
          },
          pointShape: 'diamond',
          series: {
            0: { // Year before last
              lineWidth: 3,
              //pointSize: 5,
              color: this.$util.colours.yr3,
            },
            1: { // Last year
              lineWidth: 3,
              //pointSize: 7,
              color: this.$util.colours.yr2,
            },
            2: { // This year
              lineWidth: 3,
              //pointSize: 10,
              color: this.$util.colours.yr1,
            },
          },
          tooltip: { isHtml: true },
          focusTarget: 'category',
          //fontName: 'Work Sans',
          fontName: 'Arial',
          //fontName: 'Georgia',
        }
      },
    },

    methods: {

      renderTooltip(dataArrRow) {
        let html = `<div style="padding:5px 2em; width: 30em; font-size: 1.1em;">`

        for(const period of dataArrRow) {
          if (period === null) continue

          html += `
            <p>
              Total revenue in
              <b>
                ${this.monthStampShort(DateTime.utc().set({month:period.sequence_month}))}
                ${this.yearStamp(DateTime.fromISO(period.timestamp))}
              </b>
              is
              <b>${this.bigCurrency(period.revenue)}</b>
            </p>
          `
        }
        //<b>${this.currency+period.revenue}</b>

        html += `</div>`
        return html
      },

      onChartReady(chart,google) {
        this.google = google
        this.updateData()
      },

      updateData() {
        const rdate = DateTime.fromISO(this.report.reportDate,{zone:this.report.report.timezone})

        // Parse the periods into a suitable array
        let dataArr = new Array(12).fill(null).map(() => new Array(3).fill(null)); // 12 rows, 3 columns
        for(const period of this.report.report.trends.periods) {
          dataArr[period.sequence_month - 1][period.sequence_year] = period
        }

        const data = new google.visualization.DataTable()
        data.addColumn({ type:'string', label:'Month', role:'domain' })
        data.addColumn({ type:'string', role:'tooltip', p:{ html: true } })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:2})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:1})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate) })
        //data.addColumn({ type:'string', role:'style' })
        //data.addColumn({ type:'string', role:'annotation' })

        for (let i=0;i<=11;i++) {
          data.addRow([
            this.monthStampShort(DateTime.utc().set({month:i+1})),
            this.renderTooltip(dataArr[i]),
            dataArr[i][0] ? dataArr[i][0].revenue: null,
            dataArr[i][1] ? dataArr[i][1].revenue: null,
            dataArr[i][2] ? dataArr[i][2].revenue: null,
          ])
        }

        const formatter = new google.visualization.NumberFormat({prefix: this.currency, negativeColor: 'red', negativeParens: true, fractionDigits:0 })
        formatter.format(data,1)
        formatter.format(data,2)
        formatter.format(data,3)

        this.chartData = data
      },

      bigCurrency(amount) {
        return amount ? this.currency + Number(amount.toFixed()).toLocaleString() : this.currency + Number(Number(0).toFixed()).toLocaleString()
      },

      yearStamp(dt) {
        return dt.toLocaleString({year:'numeric'})
      },

      monthStampShort(dt) {
        return dt.toLocaleString({month:'short'})
      },

    }, // methods

  }

</script>

<style scoped lang="scss">
</style>
