<template>

    <PNavigation v-if="loaded"
                 show-mobile-navigation: true
                 :items = "navigationItems"
    />

</template>


<script>
import {bus} from '@/main'
import { mapGetters } from 'vuex'

export default {
  name: "Navigation",

  data() {
    return {
      loaded: false,
      navigationItems: [],
    }
  },

  mounted() {
    this.updateNavigation()
  },

  computed: {
    merchantId() {
      return parseInt(this.$route.params.merchantId,10) || parseInt(this.defaultMerchantId,10)
    },
    ...mapGetters('authentication', [
      'isAuthenticated',
      'defaultMerchantId',
      'merchantList',
    ]),
  },

  methods: {

    updateNavigation() {
      this.loaded = false

      this.$nextTick( function() {
        let items = []
        for (const m of this.merchantList) {
          items.push(
              {
                label: m.merchantName,
                badge: m.merchantDeleted ? '' : 'Live',
                selected: m.merchantId === this.merchantId,
                onClick: () => {
                  this.selectMerchant(m.merchantId)
                },
              },
          )
        }

        this.navigationItems = [
          {
            title: 'Merchants',
            items: items,
          },

          {
            separator: true,
            items: [
              {
                label: 'Logout',
                onClick: () => {
                  this.logout()
                },

              },
            ]
          }

        ]
        this.loaded = true
      })
    },

    selectMerchant(newMerchantId) {
      this.$router.push({ name: 'Summary', params: { merchantId: newMerchantId } })
      this.updateNavigation()
      bus.$emit('merchantSelected', newMerchantId)
    },

    async logout() {
      // Logout
      try {
        await this.$store.dispatch('authentication/logout')
      }
      catch(err) {
        console.log('Error on logout:',err)
      }
      if (this.$route.name !== 'Login') this.$router.push({ name:'Login' })
    },

  },

}
</script>


<style scoped lang="scss">
</style>
