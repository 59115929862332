//
// Utility routines to help
//

// https://forum.vuejs.org/t/single-file-component-how-to-access-imported-library-in-template/8850

import getSymbolFromCurrency from 'currency-symbol-map'
import {DateTime} from 'luxon'


class Util {

// Chart colours
  colours = {
    yr1: '#4D6B9C',
    yr2: '#C0323B',
    yr3: '#FEC8C6',
  }


  currencySymbol(currencyCode) {
    return currencyCode ? getSymbolFromCurrency(currencyCode) : ''
  }

  monthStamp(d,tz) {
    return DateTime.fromISO(d,{zone:tz}).toLocaleString({month:'long'})
  }

  bigCurrency(currency,amount) {
    return amount ? currency + Number(amount.toFixed()).toLocaleString() : currency + Number(Number(0).toFixed()).toLocaleString()
  }

  bigNumber(amount) {
    return amount ? Number(amount.toFixed()).toLocaleString() : Number(Number(0).toFixed()).toLocaleString()
  }

  redOrGreen(n) {
    // Stylesheet class
    if (n < 0.01 && n > -0.01) return 'black--test';  // Between -0.1% and 0.1%
    return n<0 ? 'red--text' : 'green--text'
  }

  percentage(pc) {
    return (pc * 100).toFixed(pc<0.1 ? 1 : 0)
  }

  sign(n) {
    if (n > 0) {
      return '+'
    } else if (n < 0) {
      return '-'
    }
    return ''
  }

  unsign(n) {
    if (n >= 0) {
      return n
    } else {
      return -n
    }
  }




}
export {Util as default}
