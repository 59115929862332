<template>
  <PCard>

    <PCardSection>
      <PDisplayText size="medium" element="h2">
        <b>
          Repeat Purchase
        </b>
      </PDisplayText>

      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['fas','user-friends']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
            <p><b> {{ this.$util.unsign(this.$util.percentage(repeatPurchasePercentage)) }}% </b></p>
            <p>of new customers have shopped again </p>
            <p>within 12 months of their first purchase</p>
          </PDisplayText>
        </PStackItem>

      </PStack>
    </PCardSection>


    <PCardSection>
      <ChartRepeatPurchase
          :report="report"
          @repeatPurchaseUpdate="onRepeatPurchaseUpdate"
      />
    </PCardSection>

    <PCardSection title="Repeat Purchase" subdued>
      <PTextContainer>
        <p>What percentage of new customers you acquire come back and shop again?</p>
        <p>This indicates how loyal they are. A business in your sector typically has 35% of new customers placing their second order within 12 months of recruitment. How do your customers compare? Do you have do you have a nursery programme? How to you make sure people remember your brand when they come to shop again? What is their order experience like?</p>
        <p>There are two numbers here</p>
        <p>Second purchase – for all customers.  This looks at the time between the first and second purchase for everyone you have on your database who has shopped more than once.  This means you can see whether your customers come back – and if so when.</p>
        <p>Time between – for new customers previous 12 months.  This looks at the time between the first and second purchase for only customers who have been recruited in the last 12 months and have shopped more than once.  This means you can see whether the customers you have recruited recently are as valuable as those that you have recruited in the past.</p>
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartRepeatPurchase from '@/components/summary/charts/ChartRepeatPurchase'


export default {
  name: "SummaryRepeatPurchase",

  components: {
    ChartRepeatPurchase
  },

  props: {
    report: { type: Object, required: true },
  },

  data() {
    return {
      // Repeat Purchase new customers percentage
      repeatPurchasePercentage: 0,
      repeatPurchasePercentage12Months: 0,
    }
  },

  methods: {
    onRepeatPurchaseUpdate(repeatPurchasePercentage12Months,repeatPurchasePercentage) {
      //console.log('BAR',repeatPurchasePercentage)
      this.repeatPurchasePercentage12Months = repeatPurchasePercentage12Months
      this.repeatPurchasePercentage = repeatPurchasePercentage
    },
  }


}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
