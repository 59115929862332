

/*
Polaris:

https://polaris-vue.hulkapps.com/?path=/docs/structure-frame--default

https://shopify.dev/apps/design-guidelines/layout#responsive-layout-grid


Stuck using old polaris-vue for vue v2:
https://polaris-vue.hulkapps.com/?path=/docs/introduction--page

Really want to upgrade polaris-vue for vue v3 to:
https://ownego.github.io/polaris-vue/?path=/docs/get-started--page
 */


// Parameters passed on URL (if any)
const params = new URLSearchParams(location.search)


// Shopify App Bridge
// https://shopify.dev/apps/tools/app-bridge/getting-started/app-setup
import createApp from '@shopify/app-bridge'
//import {Toast, ResourcePicker} from '@shopify/app-bridge/actions'
let appBridge = null
if (params.has('api_key') && params.has('shopify_host')) {
  //console.log(`api_key: ${params.get('api_key')}`)
  //console.log(`host: ${params.get('shopify_host')}`)

  const config = {
    apiKey: params.get('api_key'),
    host: params.get("shopify_host"),
    forceRedirect: false
  }

  appBridge = createApp(config)
  console.log("SHOPIFY APP BRIDGE")
}




// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Environment variable should be set:
// NODE_ENV = production
// NODE_ENV = development

import Vue from 'vue'
import PolarisVue from '@hulkapps/polaris-vue'
import '@hulkapps/polaris-vue/dist/polaris-vue.min.css'
Vue.use(PolarisVue)

import App from './App'

// 2FA
// https://github.com/speakeasyjs/speakeasy

/*
https://github.com/FortAwesome/vue-fontawesome
https://blog.logrocket.com/full-guide-to-using-font-awesome-icons-in-vue-js-apps-5574c74d9b2d/
https://fontawesome.com/icons?d=gallery

Solid icons have prefix 'fas'
Regular icons have prefix 'far'
Light icons have prefix 'fal'
Brand icons have prefix 'fab'

Icons inherit the font-size of their parent container - to amend:
Sizes: sx, sm, lg, 2x, 3x, 5x, 7x, 10x

To use the following needs to be in package.json dependencies:
    "@fortawesome/fontawesome-svg-core": "^1.2.28",
    "@fortawesome/free-brands-svg-icons": "^5.13.0",
    "@fortawesome/free-regular-svg-icons": "^5.13.0",
    "@fortawesome/free-solid-svg-icons": "^5.13.0",
    "@fortawesome/vue-fontawesome": "^2.0.0",
*/
import { library } from '@fortawesome/fontawesome-svg-core'
// fas
import { faUserFriends, faChartLine, faBalanceScale, faCogs, faGlassCheers, faHandHoldingUsd, faChartBar, faHome, faSignOutAlt, faSeedling, faInfoCircle, faArrowAltCircleLeft, faTshirt} from '@fortawesome/free-solid-svg-icons'
library.add(faUserFriends, faChartLine, faBalanceScale, faCogs, faGlassCheers, faHandHoldingUsd, faChartBar, faHome, faSignOutAlt, faSeedling, faInfoCircle, faArrowAltCircleLeft, faTshirt)

// far
import { faGem } from '@fortawesome/free-regular-svg-icons'
library.add(faGem)


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)


if (process.env.NODE_ENV !== 'production') {
  console.log('NODE_ENV =',process.env.NODE_ENV)
}

export const bus = new Vue()

import router from './router'
import store from './store'

import {Settings,Info} from 'luxon'
if (Info.features().zones !== true) {
  console.log('WARNING:  No timezone support in Browser')
}
//console.log('Locale:',Settings.defaultLocale)
console.log('Browser timezone:',Settings.defaultZoneName)

Vue.config.productionTip = false

// Datatate 'Util'
import Util from './lib/Util'
Vue.prototype.$util = new Util

fetch('/api/auth/vue/reauthenticate', {
  method: 'GET',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...(params.has('auth') ? { 'Authorization': 'Bearer ' + params.get('auth') } : {})
  },
})
  .then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response
  })
  .then(r => r.json())
  .then((userlogin) => store.commit('authentication/AUTH_LOGIN', {
    userDisplayname: userlogin.displayName,
    userFullname: userlogin.fullName,
    defaultMerchantId: userlogin.merchantId,
    defaultMerchantName: userlogin.merchantName,
    readOnly: userlogin.readonly,
    allowLogout: userlogin.allowLogout,
    domain: userlogin.domain,
    merchantList: userlogin.merchantList,
  }))
  .catch(() => {
    console.log('Not logged in')
  })
  .finally(() => {
    // Start vue
    new Vue({
      el: '#app',
      router,
      store,
      components: { App },
      template: '<App/>'
    })
  })
