<template>
  <GChart
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
  />
</template>

<script>
import {DateTime} from 'luxon'
  import { GChart } from 'vue-google-charts'

  // Use K, M, etc....
  // https://stackoverflow.com/questions/15241212/google-chart-k-instead-of-thousands

  // ICU Decimal Pattern Set
  // http://cldr.unicode.org/translation/numbers-currency/number-patterns

  export default {
    name: "ChartRevenueDrivers",

    components: {
      GChart,
    },

    props: {
      report: { type: Object, required: true },
      currency: { type: String, required: true, },
    },

    watch: {
      report: function(newReport) {
        this.updateData()
      }
    },

    data() {
      return {
        google: null,
        chartData: null,
      }
    },

    computed: {
      chartOptions() {
        return {
          height: "400",
          //title: 'Revenue Drivers',
          //backgroundColor: '#F1F8E9',
          legend: {
            position: 'right',
          },
          chartArea: {
            right: '15%'
          },
          vAxis: {
            format: this.currency + '#,###',
            title: 'Revenue',
            minValue: 1, // Chart bug workaround that forces zero to bottom of axis
          },
          hAxis: {
            title: 'Customer Type',
          },
          series: [
            { // Year before last
              color: this.$util.colours.yr3,
            },
            { // Last year
              color: this.$util.colours.yr2,
            },
            { // This year
              color: this.$util.colours.yr1,
            },
          ],
          tooltip: { isHtml: true },
          focusTarget: 'category',
          fontName: 'Arial',
        }
      },
    },


    methods: {

      renderTooltip(type,dataArr,field) {
        let html = `<div style="padding:5px 2em; width: 30em; font-size: 1.1em;">`

        for(let i=0;i<3;i++) {
          html += `
          <p><b>${ this.yearStamp(DateTime.fromISO(dataArr[i].timestamp)) }</b> revenue for <b>${ type }</b> customers <b>${ this.bigCurrency(dataArr[i][field]) }</b></p>
        `
        }

        html += `</div>`
        return html
      },

      onChartReady(chart, google) {
        this.google = google
        this.updateData()
      },

      updateData() {
        // Parse the periods into a suitable array
        let dataArr = new Array(3).fill(null) // 3 rows
        for(const period of this.report.report.revenueDrivers.periods) {
          dataArr[period.sequence_year] = period
        }

        const rdate = DateTime.fromISO(this.report.reportDate,{zone:this.report.report.timezone})
        const data = new google.visualization.DataTable()
        data.addColumn({ type:'string', label:'Customer Type', role:'domain' })
        data.addColumn({ type:'string', role:'tooltip', p:{ html: true } })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:2})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:1})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate) })

        //console.log(dataArr[0])

        data.addRow([
          'All',
          this.renderTooltip('all',dataArr,'revenue'),
          dataArr[0] ? dataArr[0].revenue: null,
          dataArr[1] ? dataArr[1].revenue: null,
          dataArr[2] ? dataArr[2].revenue: null,
        ])

        data.addRow([
          'New',
          this.renderTooltip('new',dataArr,'newCustomersRevenue'),
          dataArr[0] ? dataArr[0].newCustomersRevenue: null,
          dataArr[1] ? dataArr[1].newCustomersRevenue: null,
          dataArr[2] ? dataArr[2].newCustomersRevenue: null,
        ])

        data.addRow([
          'Existing',
          this.renderTooltip('existing',dataArr,'existingCustomersRevenue'),
          dataArr[0] ? dataArr[0].existingCustomersRevenue: null,
          dataArr[1] ? dataArr[1].existingCustomersRevenue: null,
          dataArr[2] ? dataArr[2].existingCustomersRevenue: null,
        ])

        this.chartData = data
      },

      bigCurrency(amount) {
        return amount ? this.currency + Number(amount.toFixed()).toLocaleString() : this.currency + Number(Number(0).toFixed()).toLocaleString()
      },

      yearStamp(dt) {
        return dt.toLocaleString({year:'numeric'})
      },

      /*
      transpose(matrix) {
        // Works on 2D arrays to essentially swap the 'x' & 'y' axes along with values
        return matrix[0].map((col, i) => matrix.map(row => row[i]))
      },
       */

  },

  }

</script>

<style scoped lang="scss">
</style>
