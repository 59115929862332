<template>
  <PCard>

    <PCardSection>
      <PDisplayText size="medium" element="h2">
        <b>
        Customer Retention
        {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
        </b>
      </PDisplayText>
      <PDisplayText size="small" element="h2">
        vs previous
        {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
      </PDisplayText>


      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['fas','hand-holding-usd']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
          <p><b> {{ this.$util.unsign(this.$util.percentage(retentionThisYrMonth)) }}% </b></p>
          <p>vs {{ this.$util.unsign(this.$util.percentage(retentionLastYrMonth)) }}% </p>
          </PDisplayText>
        </PStackItem>

      </PStack>
    </PCardSection>


    <PCardSection>
      <ChartRetention
          :report="report"
          @retentionUpdate="onRetentionUpdate"
      />

      <p>
        The average revenue per existing customer in
        {{ this.$util.monthStamp(report.reportDate,report.report.timezone) }}
        was
        {{ this.$util.bigCurrency(currency,report.report.summary.existingCustomers.thisYear.revenuePerCustomer) }},
        {{ this.$util.sign(report.report.summary.existingCustomers.revenueGrowthPercentage) }}{{ this.$util.unsign(this.$util.percentage(report.report.summary.newCustomers.revenueGrowthPercentage)) }}%
        vs last
        {{ this.$util.monthStamp(report.reportDate,report.report.timezone) }}
        (when it was {{ this.$util.bigCurrency(currency,report.report.summary.existingCustomers.lastYear.revenuePerCustomer) }})
      </p>

    </PCardSection>

    <PCardSection title="Customer Retention" subdued>
      <PTextContainer>
        <p>The revenue drivers will have told you how important these guys are to your business – so make sure you are concentrating your efforts in the right place.  However important they are, you want to know if they are performing better or worse than last year.  So you can see that here.  An existing customer is someone who had already bought from you before at the start of the month.  So what are they worth? Are they getting more valuable to you?</p>
        <p>What percentage of your existing customers shop each month? This is the key driver of existing customer revenue.</p>
        <p>Is it getting better than last year? What marketing are you doing to get your existing customers to shop and could you do more or do it better?</p>
        <p>Remember, as your database grows, your existing customers should be driving more revenue each month, because there are more of them – but if you can also get them to be more loyal you will grow even more quickly.  Think about your email marketing –do you contact people every week on email with new messaging? Do you contact your lapsed customers? Do you target your existing customers as a custom audience on social media? How do you tell them why they should shop again?</p>
        <p>We calculate retention rate as the number of existing orderers/number of existing customers x 100.  An existing orderer is someone who had made at least 1 order before the start of the month and ordered in the reporting month.  An existing customers is someone who had made at least 1 order before the start of the month.</p>
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartRetention from '@/components/summary/charts/ChartRetention'


export default {
  name: "SummaryRetention",

  components: {
    ChartRetention
  },

  props: {
    report: { type: Object, required: true },
    currency: { type: String, required: true, },
  },

  data() {
    return {
      // Retention figures calculated by customer retention chart
      retentionThisYrMonth: 0,
      retentionLastYrMonth: 0,
    }
  },

  methods: {
    onRetentionUpdate(thisYrMonth,lastYrMonth) {
      //console.log("onRetentionUpdate",thisYrMonth,lastYrMonth)
      this.retentionThisYrMonth = thisYrMonth
      this.retentionLastYrMonth = lastYrMonth
    },
  }


}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
