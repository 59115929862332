<template>
  <GChart
      type="SteppedAreaChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
  />
</template>

<script>
  import {DateTime} from 'luxon'
  import { GChart } from 'vue-google-charts'

  // Use K, M, etc....
  // https://stackoverflow.com/questions/15241212/google-chart-k-instead-of-thousands

  export default {
    name: "ChartRepeatPurchase",

    components: {
      GChart,
    },

    props: {
      report: { type: Object, required: true },
    },

    watch: {
      report: function(newReport) {
        this.updateData()
      }
    },

    data() {
      return {
        google: null,
        chartData: null,
      }
    },

    computed: {
      chartOptions() {
        return {
          height: "400",
          title: 'Repeat Purchase',
          curveType: 'function',
          legend: {
            position: 'right',
          },
          chartArea: {
            right: '15%'
          },
          vAxis: {
            title: 'Percentage of customers that have shopped again',
            format: 'percent',
            //minValue: 1, // Chart bug workaround that forces zero to bottom of axis
            minValue: 0,
          },
          hAxis: {
            title: 'Time since first purchase',
            slantedText: true,
            slantedTextAngle:  20,
          },
          series: {
            0: {
              lineWidth: 1,
              color: '#FEC8C6', // Colour 0
            },
            /*
            1: {
              lineWidth: 1,
              color: '#4D6B9C', // Colour 3
              //lineDashStyle: [7,7],
            },
             */
          },
          tooltip: { isHtml: true },
          focusTarget: 'category',
          fontName: 'Arial',
        }
      },
    },


    methods: {

      renderTooltip(period) {
        return `
          <div style="padding:5px 2em; width: 30em; font-size: 1.1em;">
            <p><b>${this.percentage(period.percentage)}%</b> precent of <b>all</b> new customers purchased again within <b>${period.name}</b> of their first order</p>
          </div>
        `
        /*
        return `
          <div style="padding:5px 2em; width: 30em; font-size: 1.1em;">
            <p><b>${this.percentage(period.percentage)}%</b> precent of <b>all</b> new customers purchased again within <b>${period.name}</b> of their first order</p>
            <p><b>${this.percentage(period.percentage_12months)}%</b> precent of new customers <b>recruited in the last 12 months</b> purchased again within <b>${period.name}</b> of their first order</p>
          </div>
        `
         */
      },

      onChartReady(chart,google) {
        this.google = google
        this.updateData()
      },

      updateData() {
        const data = new google.visualization.DataTable()
        data.addColumn({ type:'string', label:'Months', role:'domain' })
        data.addColumn({ type:'string', role:'tooltip', p:{ html: true } })
        data.addColumn({ type:'number', label:'All customers' })
        //data.addColumn({ type:'number', label:'Customers recruited in the last 12 months' })

        for(const period of this.report.report.repeatPurchase.periods) {
          data.addRow([
            period.name,
            this.renderTooltip(period),
            period.percentage,
            //period.percentage_12months
          ])

          if (period.sequence === 11) {
            //console.log('FOO',period.percentage_12months)
            this.$emit('repeatPurchaseUpdate',period.percentage_12months,period.percentage)
          }
        }

        /*
        const formatter = new google.visualization.NumberFormat({ pattern:'#,###%', negativeColor: 'red' })
        formatter.format(data,2)
        formatter.format(data,3)
         */

        this.chartData = data
      },

      yearStamp(dt) {
        return dt.toLocaleString({year:'numeric'})
      },

      monthStampShort(dt) {
        return dt.toLocaleString({month:'short'})
      },

      percentage(pc) {
        return (pc * 100).toFixed(pc<0.1 ? 1 : 0)
      },

    },

  }

</script>

<style scoped lang="scss">
</style>
