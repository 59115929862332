<template>
  <PCard>

    <PCardSection>
      <PDisplayText size="medium" element="h2">
        <b>
          Lifetime Value
        </b>
      </PDisplayText>

      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['far','gem']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
            <p>
              <!-- The value of a customer to your business in their first 12 months is -->
              The value of a customer to your business is
            </p>
            <!-- <p> {{ bigCurrency(lifetimeValue12Months) }} </p> -->
            <p><b> {{ this.$util.bigCurrency(currency,lifetimeValue) }} </b></p>
          </PDisplayText>
        </PStackItem>

      </PStack>
    </PCardSection>


    <PCardSection>
      <ChartLifetimeValue
          :report="report"
          :currency="currency"
          @lifetimeValueUpdate="onLifetimeValueUpdate"
      />
    </PCardSection>

    <PCardSection title="Lifetime Value" subdued>
      <PTextContainer>
        <p>What is the value of a new customer to your business? How does it increase over time? How does the customer lifetime value compare to your cost per acquisition?  Fast growing businesses often recruit customers at a slight loss because they will make back their investment quickly – those growing profitably and quickly often are ok to breakeven on a new recruit up to 9 months after they recruit them – and will typically spend up to 30% of their revenue on marketing, mainly to new customers.   How do you compare?</p>
        <p>This number is given as the incremental revenue per customer, and is a slightly tricky one to explain.  When a customer places their first order this starts the clock ticking.  We don’t look at that revenue.  We then look at all subsequent revenue from that customer, and any other customers recruited on that day.  Some of them will come back and some won’t.  We take the total revenue from them all, excluding the first order revenue, and divide it by the total number of recruits on that day – and that’s the incremental revenue.  Then we calculate it over time – so 1 day, 1 month etc after the day they were recruited.</p>
        <p>Average revenue – for all customers.  This calculates the LTV including everyone on your database.</p>
        <p>Average revenue – for new customers previous 12 months.   This calculates the LTV only for customers recruited in the last 12 months so you can see whether they are more or less valuable than those you have recruited in the past.</p>
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartLifetimeValue from '@/components/summary/charts/ChartLifetimeValue'


export default {
  name: "SummaryLifetimeValue",

  components: {
    ChartLifetimeValue
  },

  props: {
    report: { type: Object, required: true },
    currency: { type: String, required: true, },
  },

  data() {
    return {
      // Lifetime Value 12 months
      lifetimeValue: 0,
      lifetimeValue12Months: 0,
    }
  },

  methods: {
    onLifetimeValueUpdate(lifetimeValue12Months,lifetimeValue) {
      this.lifetimeValue12Months = lifetimeValue12Months
      this.lifetimeValue = lifetimeValue
    },
  }


}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
