// Authentication Module in Store

import api from '@/api';
import {bus} from '@/main';


export default {
  namespaced: true,

  // State - stored state
  state: {
    // Called as:  this.$store.state.login.status
    authenticated: false,

    userDisplayname: '',
    userFullname: '',
    defaultMerchantId: 0,
    defaultMerchantName: '',
    merchantList: [],
    readOnly: true,
    allowLogout: true,
    domain: null,
  },

  // Mutations - called via action or externally - allowed to alter the state
  // Synchronous
  // Called by action as: context.commit('setGroupBy',newValue)
  // Called externally as: this.$store.commit('daterange/setGroupBy',newValue)
  mutations: {
    AUTH_LOGIN(state, session) {
      state.userDisplayname = session.userDisplayname
      state.userFullname = session.userFullname
      state.defaultMerchantId = session.defaultMerchantId
      state.defaultMerchantName = session.defaultMerchantName
      state.readOnly = session.readOnly
      state.allowLogout = session.allowLogout
      state.domain = session.domain
      state.merchantList = session.merchantList
      state.authenticated = true
    },
    AUTH_LOGOUT(state) {
      state.userDisplayname = ''
      state.userFullname = ''
      state.defaultMerchantId = 0
      state.defaultMerchantName = ''
      state.readOnly = true
      state.allowLogout = true
      state.domain = null
      state.merchantList = []
      state.authenticated = false
    },
  },

  // Actions - called externally and commits to a mutation
  // Asynchronous
  // Called as: this.$store.dispatch('login/setGroupBy','week')
  actions: {

    async login({commit, dispatch}, usernameAndPassword) {
      try {
        const auth = await api.post('/auth/vue/login',usernameAndPassword)
        if (auth.status === 201) {

          await commit('AUTH_LOGIN',{
            userDisplayname: auth.data.displayName,
            userFullname: auth.data.fullName,
            defaultMerchantId: auth.data.merchantId,
            defaultMerchantName: auth.data.merchantName,
            readOnly: auth.data.readonly,
            allowLogout: auth.data.allowLogout,
            domain: auth.data.domain,
            merchantList: auth.data.merchantList,
          })

          bus.$emit('loggedIn')
        }
      } catch (error) {
        commit('AUTH_LOGOUT', error)
        throw error
      }
    },

    async logout({commit, dispatch, state, getters}) {
      try {
        await api.delete('/auth/vue/logout')
      } catch (error) {
        console.log('Error logging out',error)
      }
      commit('AUTH_LOGOUT')
      bus.$emit('loggedOut')
    },

    forcelogout({commit, dispatch, state, getters}) {
      console.log('Forced logout')
      commit('AUTH_LOGOUT')
      bus.$emit('loggedOut')
    },

  },


  // Getters - 'computed' properties in the store
  // (useful when we wish to process state and return a derived result)
  // Called as:  this.$store.getters['login/isAuthenticated']
  getters: {
    isAuthenticated: state => state.authenticated,

    userDisplayname: state => state.userDisplayname,
    userFullname: state => state.userFullname,
    defaultMerchantId: state => state.defaultMerchantId,
    defaultMerchantName: state => state.defaultMerchantName,
    allowLogout: state => state.allowLogout,
    domain: state => state.domain,
    merchantList: state => state.merchantList,
  },

}
