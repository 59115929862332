<template>
  <GChart
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
  />
</template>

<script>
  import {DateTime} from 'luxon'
  import { GChart } from 'vue-google-charts'

  // Use K, M, etc....
  // https://stackoverflow.com/questions/15241212/google-chart-k-instead-of-thousands

  export default {
    name: "ChartRetention",

    components: {
      GChart,
    },

    props: {
      report: { type: Object, required: true },
    },

    watch: {
      report: function(newReport) {
        this.updateData()
      }
    },

    data() {
      return {
        chartData: null,
      }
    },

    computed: {
      chartOptions() {
        return {
          height: "400",
          title: 'Customer Retention',
          //backgroundColor: '#E3F2FD',
          legend: {
            position: 'right',
          },
          chartArea: {
            right: '15%'
          },
          vAxis: {
            title: '% of customers that shop',
            format: 'percent',
            //minValue: 1, // Chart bug workaround that forces zero to bottom of axis
            minValue: 0,
            //maxValue: 0.3,
          },
          hAxis: {
            title: 'Month',
          },
          series: {
            0: { // Year before last
              //lineWidth: 3,
              color: this.$util.colours.yr3,
            },
            1: { // Last year
              //lineWidth: 5,
              color: this.$util.colours.yr2,
            },
            2: { // This year
              //lineWidth: 8,
              color: this.$util.colours.yr1,
            },
          },
          tooltip: { isHtml: true },
          focusTarget: 'category',
          fontName: 'Arial',
        }
      },
    },


    methods: {

      renderTooltip(dataArrRow) {
        let html = `<div style="padding:5px 2em; width: 30em; font-size: 1.1em;">`

        for(const period of dataArrRow) {
          if (period === null) continue

          html += `
            <p>
              <b>${this.percentage(period.percentage)}%</b> of existing customers purchased in
              <b>
                ${this.monthStampShort(DateTime.utc().set({month:period.sequence_month}))}
                ${this.yearStamp(DateTime.fromISO(period.timestamp))}
              </b>
              (<b>${this.bigNumber(period.customersWhoPurchased)}</b> out of
              <b>${this.bigNumber(period.customersWithOpportunityToPurchase)}</b>)
            </p>
          `
        }

        html += `</div>`
        return html
      },

      onChartReady(chart,google) {
        this.google = google
        this.updateData()
      },

      updateData() {
        // Parse the periods into a suitable array
        let dataArr = new Array(12).fill(null).map(() => new Array(3).fill(null)); // 12 rows, 3 columns
        for(const period of this.report.report.customerRetention.periods) {
          dataArr[period.sequence_month - 1][period.sequence_year] = period
        }
        //console.log(dataArr)

        const rdate = DateTime.fromISO(this.report.reportDate,{zone:this.report.report.timezone})
        const data = new google.visualization.DataTable()
        data.addColumn({ type:'string', label:'Month', role:'domain' })
        data.addColumn({ type:'string', role:'tooltip', p:{ html: true } })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:2})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:1})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate) })

        for (let i=0;i<=11;i++) {
          data.addRow([
            this.monthStampShort(DateTime.utc().set({month:i+1})),
            this.renderTooltip(dataArr[i]),
            dataArr[i][0] ? dataArr[i][0].percentage : null,
            dataArr[i][1] ? dataArr[i][1].percentage : null,
            dataArr[i][2] ? dataArr[i][2].percentage : null,
          ])
        }

        /*
        console.log("Report month",rdate.month)
        console.log("Foo",dataArr[rdate.month-1][2].percentage) // Jan this year
        console.log("Bar",dataArr[rdate.month-1][1].percentage) // Jan last year
         */
        this.$emit('retentionUpdate',dataArr[rdate.month-1][2].percentage,dataArr[rdate.month-1][1].percentage)

        /*
        const formatter = new google.visualization.NumberFormat({ pattern:'#,###%', negativeColor: 'red' })
        formatter.format(data,2)
        formatter.format(data,3)
        formatter.format(data,4)
         */

        this.chartData = data
      },

      bigNumber(amount) {
        return amount ? Number(amount.toFixed()).toLocaleString() : Number(Number(0).toFixed()).toLocaleString()
      },

      yearStamp(dt) {
        return dt.toLocaleString({year:'numeric'})
      },

      monthStampShort(dt) {
        return dt.toLocaleString({month:'short'})
      },

      percentage(pc) {
        return (pc * 100).toFixed(pc<0.1 ? 1 : 0)
      },

    },

  }

</script>

<style scoped lang="scss">
</style>
