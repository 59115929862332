
// Axios communication to server

import axios from 'axios'
import store from '@/store'
import router from '@/router';

//baseURL: `http://172.20.0.8:3000/api`,
//baseURL: `http://localhost:3000/api`,

// Parameters passed on URL (if any)
const params = new URLSearchParams(location.search)
const api = axios.create({
  baseURL: `/api`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...(params.has('auth') ? { 'Authorization': 'Bearer ' + params.get('auth') } : {})
  },
});


api.interceptors.response.use(function (response) {
  // Success - We got a 2xx status code
  return response;
},
  function (error) {
    // Failure - We got a non 2xx status code

    if (+error.response.status === 401) { // 401 Unauthorized
      store.dispatch('authentication/forcelogout')
      if (router.currentRoute.name !== 'Login') {
        router.push({name:'Login'})
      }
    }

    if (+error.response.status === 403) { // 403 Forbidden
      if (router.currentRoute.name !== 'RootIndex') {
        //store.dispatch('authentication/reAuthenticate')
        router.push({name:'RootIndex'})
      }
    }

    return Promise.reject(error);
});


export default api
//EOF
