<template>
  <PLayout sectioned>
    <PLayoutSection>

      <PEmptyState
          heading="Next monthly report"
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>will be on</p>
        {{ nextScheduledReportAt }}
      </PEmptyState>

    </PLayoutSection>
  </PLayout>
</template>

<script>
  import api from "@/api";
  import {DateTime} from 'luxon'
  import QueryString from 'query-string'

  export default {
    name: 'SummaryNext',

    props: {
      merchantId: { required: true, type: Number, },
    },

    data() {
      return {
        nextScheduledReportAt: null,
      }
    },

    mounted() {
      this.updateData()
    },

    methods: {

      async updateData() {
        const {data:scheduleNext} = await api.get(`/reports/${this.merchantId}/scheduleNext?` + QueryString.stringify({
          method: 'summaryReport',
        }),
            // body - if any
        )

        this.nextScheduledReportAt = DateTime.fromISO(scheduleNext.scheduledReportAt).toLocaleString({
          weekday: 'long',
          month: 'long',
          day: 'numeric'
        })
      },

    }, // methods

  }
</script>

<style>
</style>
