<template>

  <PFrame v-if="loaded">

    <template v-slot:navigation v-if="isAuthenticated && merchantList.length > 1">
      <Navigation/>
    </template>

    <template v-slot:default>
      <router-view :key="$route.fullPath"/>
    </template>

  </PFrame>

</template>


<script>
import {bus} from '@/main'
import { mapGetters } from 'vuex'

import Navigation from '@/components/navigation/Navigation'

export default {
  name: 'app',
  components: {
    Navigation,
  },

  data() {
    return {
      loaded: true,
    }
  },

  mounted() {
    bus.$on('loggedIn', this.redraw)
    bus.$on('loggedOut', this.redraw)
  },

  beforeDestroy() {
    bus.$off('loggedIn', this.redraw)
    bus.$off('loggedOut', this.redraw)
  },


  computed: {
    merchantId() {
      return parseInt(this.$route.params.merchantId,10) || parseInt(this.defaultMerchantId,10)
    },
    ...mapGetters('authentication', [
      'isAuthenticated',
      'merchantList',
    ]),
  },

  methods: {
    redraw() {
      this.loaded = false
      this.$nextTick( function() {
        this.loaded = true
      })
    }
  },

}
</script>


<style lang="scss">
</style>
