<template>
  <div/>
</template>

<script>
// The '/' path for the site

  import { mapGetters } from 'vuex'

  export default {
    name: 'RootIndex',

    computed: {
      ...mapGetters('authentication', [
        'isAuthenticated',
        'defaultMerchantId',
      ]),
    },

    mounted() {
      // Basically we never want to spend time on the RootIndex '/' so redirect away as soon as we can

      if (this.isAuthenticated) {
        this.$router.replace({ name: 'Summary', params: { merchantId: this.defaultMerchantId} })
      } else {
        this.$router.replace({name: 'Login'})
      }
    },

  }
</script>

<style>
</style>
