<template>
  <PPage
      :title="title"
      :pagination="pagination"
  >
    <PBanner title="New report" status="info" :action="{}" v-if="newReportAvailable">
      <PStack vertical>
        <PStackItem> <p>You have a new monthly report</p> </PStackItem>
        <PStackItem> <PButton size="slim" primary @click="selectLatestReport">View latest report</PButton> </PStackItem>
      </PStack>
    </PBanner>

    <SummaryLoading
        v-if="view === 'loading'"
        :merchant-id="merchantId"
    />

    <SummaryReport
        v-if="view === 'report'"
        :merchant-id="merchantId"
        :report="report"
        @selectReport="selectReport"
        @summaryNext="selectSummaryNext"
    />

    <SummaryNext
        v-if="view === 'next'"
        :merchant-id="merchantId"
        @selectReport="selectReport"
    />

  </PPage>
</template>


<script>
import SummaryLoading from '@/components/summary/SummaryLoading'
import SummaryReport from '@/components/summary/SummaryReport'
import SummaryNext from '@/components/summary/SummaryNext'

import api from '@/api'

import {DateTime} from 'luxon'

export default {
  name: 'Summary',

  components: {
    SummaryLoading,
    SummaryReport,
    SummaryNext
  },

  data() {
    return {
      title: null, // dateStamp(report.reportDate,report.report.timezone)
      view: null,

      report: null,
      reportId: null,

      newReportAvailable: false,
      reportList: null,

      pagination: {
        hasNext: true,
        hasPrevious: true,
        label: null,
        onNext: () => {
          this.nextPage()
        },
        onPrevious: () => {
          this.previousPage()
        },
      },

      repeat_timer: null,
    }
  },

  mounted() {
    this.doMounted()

    // Check every 10 seconds to see if we have any new reports
    this.repeat_timer = setInterval(function () {
      api.get(`/reports/${this.merchantId}/?method=summary_report`).then( ({data:reportList}) => {
        if (reportList.length !== this.reportList.length) {
          if (this.reportList.length === 0) {
            // First report
            const reportId = reportList[reportList.length - 1].reportId
            this.reportList = reportList
            this.selectReport(reportId)
          } else {
            this.reportList = reportList
            this.newReportAvailable = true
          }
        }
      })
    }.bind(this),10000)  // Every 10 seconds
  },
  beforeDestroy() {
    clearInterval(this.repeat_timer)
  },

  computed: {
    merchantId() {
      return parseInt(this.$route.params.merchantId,10) || parseInt(this.defaultMerchantId,10)
    },
  },

  methods: {

    async doMounted() {
      const {data:reportList} = await api.get(`/reports/${this.merchantId}/?method=summary_report`)
      this.reportList = reportList

      if (reportList.length === 0) {
        this.pagination.hasPrevious = false
        this.pagination.hasNext = false
        this.title = null
        this.view = 'loading'
      }
      else {
        await this.selectLatestReport()
      }
    },

    async selectLatestReport() {
      const reportId = this.reportList[this.reportList.length - 1].reportId
      this.newReportAvailable = false
      await this.selectReport(reportId)
    },

    async selectReport(reportId) {
      this.reportId = reportId
      this.pagination.hasPrevious = this.reportList[0].reportId !== reportId  // First report?
      this.pagination.hasNext = true

      const {data:report} = await api.get(`/reports/${this.merchantId}/${reportId}`)
      if (report.method !== 'summaryReport') {
        console.log('Loaded report is not a summaryReport')
        this.report = null
        return
      }

      this.title = this.dateStamp(report.reportDate,report.report.timezone)
      this.report = report
      this.view = 'report'
      //console.dir(this.report,{depth:null})
    },

    selectSummaryNext() {
      this.pagination.hasNext = false
      this.title = null
      this.view = 'next'
    },


    nextPage() {
      const index = this.reportList.findIndex(item => item.reportId === this.reportId)
      if (this.reportList.length - 1 === index) {
        this.selectSummaryNext()
      }
      else {
        const reportId = this.reportList[index + 1].reportId
        this.selectReport(reportId)
      }
    },

    async previousPage() {
      let reportId
      if (this.view === 'next') {
        reportId = this.reportList[this.reportList.length - 1].reportId
      }
      else {
        const index = this.reportList.findIndex(item => item.reportId === this.reportId)
        reportId = this.reportList[index - 1].reportId
      }
      await this.selectReport(reportId)
    },


    dateStamp(d,tz) {
      return DateTime.fromISO(d,{zone:tz}).toLocaleString({month:'long',year:'numeric'})
    },

  }, // methods

}
</script>

<style scoped>
</style>
