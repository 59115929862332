<template>
  <GChart
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
  />
</template>

<script>
  import {DateTime} from 'luxon'
  import { GChart } from 'vue-google-charts'

  // Use K, M, etc....
  // https://stackoverflow.com/questions/15241212/google-chart-k-instead-of-thousands

  export default {
    name: "ChartTotalCustomers",

    components: {
      GChart,
    },

    props: {
      report: { type: Object, required: true },
      currency: { type: String, required: true, },
    },

    watch: {
      report: function(newReport) {
        this.updateData()
      }
    },

    data() {
      return {
        google: null,
        chartData: null,
      }
    },

    computed: {
      chartOptions() {
        return {
          height: "400",
          title: 'Customer Database Size',
          curveType: 'function',
          legend: {
            position: 'right',
          },
          chartArea: {
            right: '15%'
          },
          vAxis: {
            title: 'Number of unique customers',
            minValue: 1, // Chart bug workaround that forces zero to bottom of axis
          },
          hAxis: {
            title: 'Month',
          },
          //pointShape: 'diamond',
          series: {
            0: { // Year before last
              lineWidth: 2,
              color: this.$util.colours.yr3,
            },
            1: { // Last year
              lineWidth: 2,
              color: this.$util.colours.yr2,
              //lineDashStyle: [7,7],
            },
            2: { // This year
              lineWidth: 2,
              color: this.$util.colours.yr1,
              //lineDashStyle: [7,7],
            },
          },
          tooltip: { isHtml: true },
          focusTarget: 'category',
          fontName: 'Arial',
          // --p-font-family-sans
        }
      },
    },

    methods: {

      renderTooltip(dataArrRow) {
        let html = `<div style="padding:5px 2em; width: 30em; font-size: 1.1em;">`

        for(const period of dataArrRow) {
          if (period === null) continue

          html += `
            <p>
              <b>${this.bigNumber(period.customersWithOpportunityToPurchase)}</b> customers by
              <b>
                ${this.monthStampShort(DateTime.utc().set({month:period.sequence_month}))}
                ${this.yearStamp(DateTime.fromISO(period.timestamp))}
              </b>
            </p>
          `
        }

        html += `</div>`
        return html
      },

      onChartReady(chart,google) {
        this.google = google
        this.updateData()
      },

      updateData() {
        const rdate = DateTime.fromISO(this.report.reportDate,{zone:this.report.report.timezone})

        let totalCustomersThisYrMonth = 0
        let totalCustomersLastYrMonth = 0
        let totalCustomersPercentageChange = 0

        // Parse the periods into a suitable array
        let dataArr = new Array(12).fill(null).map(() => new Array(3).fill(null)); // 12 rows, 3 columns
        for(const period of this.report.report.customerRetention.periods) {
          dataArr[period.sequence_month - 1][period.sequence_year] = period

          if ((period.sequence_month === rdate.month) && (period.sequence_year === 2)) {
            totalCustomersThisYrMonth = period.customersWithOpportunityToPurchase
          }

          if ((period.sequence_month === rdate.month) && (period.sequence_year === 1)) {
            totalCustomersLastYrMonth = period.customersWithOpportunityToPurchase
          }
        }

        if (totalCustomersLastYrMonth !== 0) {
          totalCustomersPercentageChange = (totalCustomersThisYrMonth - totalCustomersLastYrMonth) / totalCustomersLastYrMonth
        }
        this.$emit('totalCustomersUpdate',totalCustomersThisYrMonth,totalCustomersLastYrMonth,totalCustomersPercentageChange)


        const data = new google.visualization.DataTable()
        data.addColumn({ type:'string', label:'Month', role:'domain' })
        data.addColumn({ type:'string', role:'tooltip', p:{ html: true } })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:2})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate.minus({years:1})) })
        data.addColumn({ type:'number', label:this.yearStamp(rdate) })

        for (let i=0;i<=11;i++) {
          data.addRow([
            this.monthStampShort(DateTime.utc().set({month:i+1})),
            this.renderTooltip(dataArr[i]),
            dataArr[i][0] ? dataArr[i][0].customersWithOpportunityToPurchase: null,
            dataArr[i][1] ? dataArr[i][1].customersWithOpportunityToPurchase: null,
            dataArr[i][2] ? dataArr[i][2].customersWithOpportunityToPurchase: null,
          ])
        }

        /*
        const formatter = new google.visualization.NumberFormat({prefix: this.currency, negativeColor: 'red', negativeParens: true, fractionDigits:2 })
        formatter.format(data,2)
        formatter.format(data,3)
         */

        this.chartData = data
      },

      bigNumber(amount) {
        return amount ? Number(amount.toFixed()).toLocaleString() : Number(Number(0).toFixed()).toLocaleString()
      },

      yearStamp(dt) {
        return dt.toLocaleString({year:'numeric'})
      },

      monthStampShort(dt) {
        return dt.toLocaleString({month:'short'})
      },

    },

  }

</script>

<style scoped lang="scss">
</style>
