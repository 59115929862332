import Vue from 'vue'
import Router from 'vue-router'

import store from '../store'

import RootIndex from '@/components/navigation/RootIndex'
import Login from '@/components/navigation/Login'

import Summary from '@/components/summary/Summary'
import SummaryLoading from '@/components/summary/SummaryLoading'
import SummaryReport from '@/components/summary/SummaryReport'
import SummaryNext from '@/components/summary/SummaryNext'

Vue.use(Router);

// Using vue for authentication
// https://blog.sqreen.com/authentication-best-practices-vue/

const ifAuthenticated = (to, from, next) => {
  if (!store.getters['authentication/isAuthenticated']) {
    return next({ name: 'Login' })
  }
  return next()
}

const ifMultipleMerchants = (to, from, next) => {
  if (store.getters['authentication/merchantList'].length > 1) {
    return next()
  }
  return next({ name: 'RootIndex' })
}


export default new Router({
  // Default mode is 'hash' which puts a # in the uri.  'history' mode used in conjunction with nginx config
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'RootIndex',
      component: RootIndex
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/:merchantId(\\d+)/summary',
      name: 'Summary',
      component: Summary,
      beforeEnter: ifAuthenticated,
    },



    {
      path: '/:merchantId(\\d+)/summaryLoading',
      name: 'SummaryLoading',
      component: SummaryLoading,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/:merchantId(\\d+)/summary/:reportId(\\d+)',
      name: 'SummaryReport',
      component: SummaryReport,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/:merchantId(\\d+)/summaryNext',
      name: 'SummaryNext',
      component: SummaryNext,
      beforeEnter: ifAuthenticated,
    },


  ]
});
