<template>
  <PTabs
      :tabs="tabs"
      :selected="selectedTabIndex"
      @select="handleTabChange"
  >
    <PLayout sectioned>
      <PLayoutSection>

        <SummaryRevenueTrends
            v-if="selectedTabIndex === 0"
            :report="report"
            :currency="currency"
        />

        <SummaryRevenueDrivers
            v-if="selectedTabIndex === 1"
            :report="report"
            :currency="currency"
        />

        <SummaryAcquisition
            v-if="selectedTabIndex === 2"
            :report="report"
            :currency="currency"
        />

        <SummaryRetention
            v-if="selectedTabIndex === 3"
            :report="report"
            :currency="currency"
        />

        <SummaryRepeatPurchase
            v-if="selectedTabIndex === 4"
            :report="report"
        />

        <SummaryLifetimeValue
            v-if="selectedTabIndex === 5"
            :report="report"
            :currency="currency"
        />

        <SummaryTotalCustomers
            v-if="selectedTabIndex === 6"
            :report="report"
            :currency="currency"
        />

      </PLayoutSection>

      <PLayoutSection>
        <PTextContainer>
          <PTextStyle variation="strong">
            Note: All figures, unless otherwise indicated, are based on customer orders and include sales tax and exclude postage
          </PTextStyle>
        </PTextContainer>
      </PLayoutSection>

    </PLayout>

  </PTabs>
</template>


<script>
  import api from '@/api'
  import {mapGetters} from 'vuex'
  //import QueryString from 'query-string'

  import {DateTime} from 'luxon'

  import SummaryRevenueTrends from '@/components/summary/section/SummaryRevenueTrends'
  import SummaryRevenueDrivers from '@/components/summary/section/SummaryRevenueDrivers'
  import SummaryAcquisition from '@/components/summary/section/SummaryAcquisition'
  import SummaryRetention from '@/components/summary/section/SummaryRetention'
  import SummaryRepeatPurchase from '@/components/summary/section/SummaryRepeatPurchase'
  import SummaryLifetimeValue from '@/components/summary/section/SummaryLifetimeValue'
  import SummaryTotalCustomers from '@/components/summary/section/SummaryTotalCustomers'

  export default {
    name: 'Report',

    components: {
      SummaryRevenueTrends,
      SummaryRevenueDrivers,
      SummaryAcquisition,
      SummaryRetention,
      SummaryRepeatPurchase,
      SummaryLifetimeValue,
      SummaryTotalCustomers,
    },

    props: {
      merchantId: { required: true, type: Number, },
      report: { type: Object, required: true },
    },

    data() {
      return {
        selectedTabIndex: 0,
        tabs: [
          {
            id: 'revenuetrends',
            content: 'Revenue',
          },
          {
            id: 'revenuedrivers',
            content: 'Drivers',
          },
          {
            id: 'acquisition',
            content: 'Acquisition',
          },
          {
            id: 'retention',
            content: 'Retention',
          },
          {
            id: 'repeatpurchase',
            content: 'Repeat Purchase',
          },
          {
            id: 'lifetimevalue',
            content: 'Lifetime Value',
          },
          {
            id: 'totalcustomers',
            content: 'Customers',
          },
        ],
      }
    },

    computed: {
      ...mapGetters('authentication', [
        'merchantList',
      ]),
      currencyCode() {
        return this.merchantList.find(m => m.merchantId === this.merchantId)?.merchantCurrencyCode
      },
      currency() {
        return this.$util.currencySymbol(this.currencyCode)
      },
    },

    methods: {
      handleTabChange(selectedTabIndex) {
        this.selectedTabIndex = selectedTabIndex
      },
    },

  }
</script>

<style scoped lang="scss">
</style>
