<template>
  <GChart
      type="AreaChart"
      :data="chartData"
      :options="chartOptions"
      @ready="onChartReady"
  />
</template>

<script>
  import {DateTime} from 'luxon'
  import { GChart } from 'vue-google-charts'

  // Use K, M, etc....
  // https://stackoverflow.com/questions/15241212/google-chart-k-instead-of-thousands

  export default {
    name: "ChartLifetimeValue",

    components: {
      GChart,
    },

    props: {
      report: { type: Object, required: true },
      currency: { type: String, required: true, },
    },

    watch: {
      report: function(newReport) {
        this.updateData()
      }
    },

    data() {
      return {
        google: null,
        chartData: null,
      }
    },

    computed: {
      chartOptions() {
        return {
          height: "400",
          title: 'Lifetime Value',
          curveType: 'function',
          legend: {
            position: 'right',
          },
          chartArea: {
            right: '15%'
          },
          vAxis: {
            title: 'Incremental revenue (excluding first purchase)',
            format: this.currency + '#,###',
            minValue: 1, // Chart bug workaround that forces zero to bottom of axis
          },
          hAxis: {
            title: 'Number of months since first purchase',
            slantedText: true,
            slantedTextAngle:  20,
          },
          pointShape: 'diamond',
          series: {
            0: {
              lineWidth: 2,
              //pointSize: 7,
              color: '#FFCC80',
            },
            /*
            1: {
              lineWidth: 2,
              //pointSize: 7,
              color: '#80CBC4',
              lineDashStyle: [7,7],
            },
             */
          },
          tooltip: { isHtml: true },
          focusTarget: 'category',
          fontName: 'Arial',
        }
      },
    },

    methods: {

      renderTooltip(period) {
        return `
          <div style="padding:5px 2em; width: 30em; font-size: 1.1em;">
            <p>Average total revenue for all new customers <b>${period.name}</b> after their first order is <b>${this.bigCurrency(period.revenuePerCustomer)}</b></p>
          </div>
        `
        /*
        return `
          <div style="padding:5px 2em; width: 30em; font-size: 1.1em;">
            <p>Average total revenue for all new customers <b>${period.name}</b> after their first order is <b>${this.bigCurrency(period.revenuePerCustomer)}</b></p>
            <p>Average total revenue for customers less than 12 months old after <b>${period.name}</b> after their first order is <b>${this.bigCurrency(period.revenuePerCustomer_12months)}</b></p>
          </div>
        `
         */
      },

      onChartReady(chart,google) {
        this.google = google
        this.updateData()
      },

      updateData() {
        const data = new google.visualization.DataTable()
        data.addColumn({ type:'string', label:'Months', role:'domain' })
        data.addColumn({ type:'string', role:'tooltip', p:{ html: true } })
        data.addColumn({ type:'number', label:'All customers' })
        //data.addColumn({ type:'number', label:'Customers recruited in the last 12 months' })

        for(const period of this.report.report.lifetimeValue.periods) {
          data.addRow([
            period.name,
            this.renderTooltip(period),
            period.revenuePerCustomer,
            //period.revenuePerCustomer_12months
          ])

          if (period.sequence === 11) {
            //console.log('FOO',period.revenuePerCustomer_12months)
            this.$emit('lifetimeValueUpdate',period.revenuePerCustomer_12months,period.revenuePerCustomer)
          }
        }

        const formatter = new google.visualization.NumberFormat({prefix: this.currency, negativeColor: 'red', negativeParens: true, fractionDigits:2 })
        formatter.format(data,2)
        //formatter.format(data,3)

        this.chartData = data
      },

      bigCurrency(amount) {
        return amount ? this.currency + Number(amount.toFixed()).toLocaleString() : this.currency + Number(Number(0).toFixed()).toLocaleString()
      },

      yearStamp(dt) {
        return dt.toLocaleString({year:'numeric'})
      },

      monthStampShort(dt) {
        return dt.toLocaleString({month:'short'})
      },

    },

  }

</script>

<style scoped lang="scss">
</style>
