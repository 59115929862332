<template>
  <PLayout sectioned>
    <PLayoutSection>

      <PEmptyState
          heading="Analysing Orders"
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p slot="footer">
          Please wait; this may take a while
        </p>
        <PProgressBar size="medium" :progress="ordersSyncPercentage"/>
      </PEmptyState>

    </PLayoutSection>
  </PLayout>
</template>

<script>
  import api from "@/api";
  import {DateTime} from 'luxon'

  export default {
    name: 'SummaryLoading',

    props: {
      merchantId: { required: true, type: Number, },
    },

    data() {
      return {
        ordersSyncPercentage: 100,
        repeat_timer: null,
      }
    },

    mounted() {
      this.updateData()

      // Refresh data regularly
      this.repeat_timer = setInterval(function () {
        this.updateData()
      }.bind(this),5000)  // Every 5 seconds
    },
    beforeDestroy() {
      clearInterval(this.repeat_timer)
    },

    methods: {

      async updateData() {
        //console.log('Updating status data...')
        const response = await api.get(`/collector/${this.merchantId}/status`)
        const ordersSyncTo = DateTime.fromISO(response.data.ordersSyncTo)
        const {days: diffDays} = DateTime.utc().diff(ordersSyncTo, 'days').toObject()
        const maxdays = 365 * 3

        if (response.status === 204) {
          // No Content
          this.ordersSyncTo = 100
          //console.log('allow_sync disabled for merchant')
        }
        else if (diffDays > maxdays) {
          // Have not started yet
          this.ordersSyncPercentage = 0
          //console.log(`Sync:Not started (0%)`)
        }
        else if (diffDays < 1) {
          // Mostly up to date
          this.ordersSyncPercentage = 90
          //console.log(`Sync:Finished (100%)`)
        }
        else {
          // Calculate percentage
          this.ordersSyncPercentage = Math.floor(100 - ((diffDays / maxdays) * 100))
          //console.log(`Sync:${Math.floor(diffDays)} (${this.ordersSyncPercentage}%)`)
        }
      }

    }, // methods

  }
</script>

<style>
</style>
