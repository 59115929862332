<template>
  <PCard>

    <PCardSection>
      <PDisplayText size="medium" element="h2">
        <b>
          Revenue Drivers
          {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
        </b>
      </PDisplayText>
      <PDisplayText size="small" element="h2">
        vs previous
        {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
      </PDisplayText>


      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['fas','cogs']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
            <p><b>New Customers</b></p>
            <p><b>{{ this.$util.bigCurrency(currency,report.report.summary.newCustomers.thisYear.revenue) }} </b></p>
            <p>vs {{ this.$util.bigCurrency(currency,report.report.summary.newCustomers.lastYear.revenue) }} </p>
          </PDisplayText>

          <PDisplayText size="medium">
            <p :class="this.$util.redOrGreen(report.report.summary.newCustomers.revenueGrowthPercentage)">
              {{ this.$util.sign(report.report.summary.newCustomers.revenueGrowthPercentage) }}
              {{ this.$util.unsign(this.$util.percentage(report.report.summary.newCustomers.revenueGrowthPercentage)) }} %
            </p>
          </PDisplayText>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
            <p><b>Existing Customers</b</p>
            <p><b> {{ this.$util.bigCurrency(currency,report.report.summary.existingCustomers.thisYear.revenue) }} </b></p>
            <p>vs {{ this.$util.bigCurrency(currency,report.report.summary.existingCustomers.lastYear.revenue) }} </p>
          </PDisplayText>

          <PDisplayText size="medium">
            <p class="font-weight-bold text-center mb-0 text-h4" :class="this.$util.redOrGreen(report.report.summary.existingCustomers.revenueGrowthPercentage)">
              {{ this.$util.sign(report.report.summary.existingCustomers.revenueGrowthPercentage) }}
              {{ this.$util.unsign(this.$util.percentage(report.report.summary.existingCustomers.revenueGrowthPercentage)) }} %
            </p>
          </PDisplayText>
        </PStackItem>

      </PStack>
    </PCardSection>


    <PCardSection>
      <ChartRevenueDrivers
          :report="report"
          :currency="currency"
          style="height: 100%; align-items:center; display:flex"
      />
    </PCardSection>

    <PCardSection title="Revenue Drivers" subdued>
      <PTextContainer>
        <p>The really interesting numbers.  We’re starting to understand the “why”.</p>
        <p>Revenue can only come from new customers or existing customers. Which type of customers are driving your business performance? Is it same as last year?</p>
        <p>Just so you know, for a growing business typically 70% of revenue will come from new customers.  Otherwise there won’t be sustainable growth as you will lose customers faster than you gain them. How do you compare to this benchmark?</p>
        <p>New customer revenue is revenue from anyone who had never bought before at the start of the month.  Existing customer revenue is revenue from anyone who had already bought before at least once at the start of the month, and spend money in the month.</p>
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartRevenueDrivers from '@/components/summary/charts/ChartRevenueDrivers'


export default {
  name: "SummaryRevenueDrivers",

  components: {
    ChartRevenueDrivers,
  },

  props: {
    report: { type: Object, required: true },
    currency: { type: String, required: true, },
  },


}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
