<template>
  <PCard>

    <PCardSection>
      <PDisplayText size="medium" element="h2">
        <b>
          New Customers
          {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
        </b>
      </PDisplayText>
      <PDisplayText size="small" element="h2">
        vs previous
        {{this.$util.monthStamp(report.reportDate,report.report.timezone)}}
      </PDisplayText>


      <PStack distribution="fill" alignment="center">
        <PStackItem class="centre">
          <font-awesome-icon :icon="['fas','seedling']" size="3x" class="icon2"/>
        </PStackItem>

        <PStackItem class="centre">
          <PDisplayText size="small">
            <p><b> {{ this.$util.bigNumber(report.report.summary.newCustomers.thisYear.cardinal) }} </b></p>
            <p>vs {{ this.$util.bigNumber(report.report.summary.newCustomers.lastYear.cardinal) }} </p>
          </PDisplayText>

          <PDisplayText size="medium">
            <p class="font-weight-bold text-center mb-0 text-h4" :class="this.$util.redOrGreen(report.report.summary.newCustomers.cardinalGrowthPercentage)">
              {{ this.$util.sign(report.report.summary.newCustomers.cardinalGrowthPercentage) }}
              {{ this.$util.unsign(this.$util.percentage(report.report.summary.newCustomers.cardinalGrowthPercentage)) }} %
            </p>
          </PDisplayText>
        </PStackItem>

      </PStack>
    </PCardSection>


    <PCardSection>
          <ChartAcquisition
              :report="report"
          />

          <p>
            The average revenue per new customer in
            {{ this.$util.monthStamp(report.reportDate,report.report.timezone) }}
            was

            {{ this.$util.bigCurrency(currency,report.report.summary.newCustomers.thisYear.revenuePerCustomer) }},
            {{ this.$util.sign(report.report.summary.newCustomers.revenueGrowthPercentage) }}{{ this.$util.unsign(this.$util.percentage(report.report.summary.newCustomers.revenueGrowthPercentage)) }}%

            vs last
            {{ this.$util.monthStamp(report.reportDate,report.report.timezone) }}
            (when it was {{ this.$util.bigCurrency(currency,report.report.summary.newCustomers.lastYear.revenuePerCustomer) }})
          </p>
    </PCardSection>

    <PCardSection title="Customer Acquisition" subdued>
      <PTextContainer>
        <p>Anyone who had never bought before at the start of the month, and ordered in the month, will be in here.  If they shop more than once in the month they will count as 1 new customer, but with 2 orders, so their revenue/customer might be higher than your average transaction value.</p>
        <p>We love a trend at datatate and this is an important one as acquiring new customers is vital to business growth - how many do you get each month? Are you getting more than last year?</p>
        <p>If you compare to your marketing spend you should be able to calculate your cost per acquisition for each month. Is it changing? How can you get more new customers?  Do you know where your new customers are coming from? You can use google analytics to get a bit of an idea of this, but do remember their definition of “new” is a new website visitor – they might have already bought from another device, or a long time ago so might not be really new.  Their data is also last click so it might not tell you the whole story of where the customer has found out about you – but it’s still a useful indicator.</p>
        <p>There are lots of channels for getting new customers, and some are very low cost and easy to try, particularly digital channels. Do you have a refer a friend scheme? Do you use google shopping and facebook advertising alongside more traditional media? Have you thought about trying a catalogue or press ad?</p>
        <p>When you know your cost per acquisition you can also use this with your lifetime value metric to make sure you are investing the right amount of money in marketing.</p>
      </PTextContainer>
    </PCardSection>

  </PCard>
</template>

<script>
// https://polaris.shopify.com/

import ChartAcquisition from '@/components/summary/charts/ChartAcquisition'


export default {
  name: "SummaryAcquisition",

  components: {
    ChartAcquisition,
  },

  props: {
    report: { type: Object, required: true },
    currency: { type: String, required: true, },
  },

}
</script>

<style scoped>
.centre {
  text-align: center;
}
</style>
